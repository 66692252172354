import { LineType } from './lines';

export const line_kyongei: LineType[][] = [
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 1,
      station_name: '서울',
      next_station: [2],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 2,
      station_name: '신촌',
      next_station: [3],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 3,
      station_name: '가좌',
      next_station: [4, 37],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 4,
      station_name: '홍대입구',
      next_station: [5],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 37,
      station_name: '디지털미디어시티',
      next_station: [38],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 5,
      station_name: '서강대',
      next_station: [6],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 38,
      station_name: '수색',
      next_station: [39],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 6,
      station_name: '공덕',
      next_station: [7],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 39,
      station_name: '화전',
      next_station: [40],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 7,
      station_name: '효창공원앞',
      next_station: [8],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 40,
      station_name: '강매',
      next_station: [41],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 8,
      station_name: '용산',
      next_station: [9],
      toilet: 'GATE',
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 41,
      station_name: '행신',
      next_station: [42],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 9,
      station_name: '이촌',
      next_station: [10],
      toilet: 'GATE',
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 42,
      station_name: '능곡',
      next_station: [43],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 10,
      station_name: '서빙고',
      next_station: [11],
      toilet: 'PLATFORM',
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 43,
      station_name: '대곡',
      next_station: [44],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 11,
      station_name: '한남',
      next_station: [12],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 44,
      station_name: '곡산',
      next_station: [45],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 12,
      station_name: '옥수',
      next_station: [13],
      toilet: 'GATE',
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 45,
      station_name: '백마',
      next_station: [46],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 13,
      station_name: '응봉',
      next_station: [14],
      toilet: 'PLATFORM',
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 46,
      station_name: '풍산',
      next_station: [47],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 14,
      station_name: '왕십리',
      next_station: [15],
      toilet: 'GATE',
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 47,
      station_name: '일산',
      next_station: [48],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 15,
      station_name: '청량리',
      next_station: [16],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 48,
      station_name: '탄현',
      next_station: [49],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 16,
      station_name: '회기',
      next_station: [17],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 49,
      station_name: '야당',
      next_station: [50],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 17,
      station_name: '중랑',
      next_station: [18],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 50,
      station_name: '운정',
      next_station: [51],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 18,
      station_name: '상봉',
      next_station: [19],
      toilet: 'GATE',
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 51,
      station_name: '금릉',
      next_station: [52],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 19,
      station_name: '망우',
      next_station: [20],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 52,
      station_name: '금촌',
      next_station: [53],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 20,
      station_name: '양원',
      next_station: [21],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 53,
      station_name: '월롱',
      next_station: [54],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 21,
      station_name: '구리',
      next_station: [22],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 54,
      station_name: '파주',
      next_station: [55],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 22,
      station_name: '도농',
      next_station: [23],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 55,
      station_name: '문산',
      next_station: [56],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 23,
      station_name: '양정',
      next_station: [24],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 56,
      station_name: '운천',
      next_station: [57],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 24,
      station_name: '덕소',
      next_station: [25],
    },
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 57,
      station_name: '임진강',
      next_station: undefined,
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 25,
      station_name: '도심',
      next_station: [26],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 26,
      station_name: '팔당',
      next_station: [27],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 27,
      station_name: '운길산',
      next_station: [28],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 28,
      station_name: '양수',
      next_station: [29],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 29,
      station_name: '신원',
      next_station: [30],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 30,
      station_name: '국수',
      next_station: [31],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 31,
      station_name: '아신',
      next_station: [32],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 32,
      station_name: '오빈',
      next_station: [33],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 33,
      station_name: '양평',
      next_station: [34],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 34,
      station_name: '원덕',
      next_station: [35],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 35,
      station_name: '용문',
      next_station: [36],
    },
  ],
  [
    {
      line_id: 12,
      line_name: '경의중앙선',
      station_id: 36,
      station_name: '지평',
      next_station: undefined,
    },
  ],
];
export const line_kyongei_flat = line_kyongei.reduce(function (acc, cur) {
  return acc.concat(cur);
});
