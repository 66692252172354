import { LineType } from './lines';

export const line_3: LineType[][] = [
  [{ line_id: 3, line_name: '3호선', station_id: 1, station_name: '대화', next_station: [2] }],
  [{ line_id: 3, line_name: '3호선', station_id: 2, station_name: '주엽', next_station: [3] }],
  [{ line_id: 3, line_name: '3호선', station_id: 3, station_name: '정발산', next_station: [4] }],
  [{ line_id: 3, line_name: '3호선', station_id: 4, station_name: '마두', next_station: [5] }],
  [{ line_id: 3, line_name: '3호선', station_id: 5, station_name: '백석', next_station: [6] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 6,
      station_name: '대곡',
      next_station: [7],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 7, station_name: '화정', next_station: [8] }],
  [{ line_id: 3, line_name: '3호선', station_id: 8, station_name: '원당', next_station: [9] }],
  [{ line_id: 3, line_name: '3호선', station_id: 9, station_name: '원흥', next_station: [10] }],
  [{ line_id: 3, line_name: '3호선', station_id: 10, station_name: '삼송', next_station: [11] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 11,
      station_name: '지축',
      next_station: [12],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 12, station_name: '구파발', next_station: [13] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 13,
      station_name: '연신내',
      next_station: [14],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 14, station_name: '불광', next_station: [15] }],
  [{ line_id: 3, line_name: '3호선', station_id: 15, station_name: '녹번', next_station: [16] }],
  [{ line_id: 3, line_name: '3호선', station_id: 16, station_name: '홍제', next_station: [17] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 17,
      station_name: '무악재',
      next_station: [18],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 18,
      station_name: '독립문',
      next_station: [19],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 19,
      station_name: '경복궁',
      next_station: [20],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 20, station_name: '안국', next_station: [21] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 21,
      station_name: '종로3가',
      next_station: [22],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 22,
      station_name: '을지로3가',
      next_station: [23],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 23,
      station_name: '충무로',
      next_station: [24],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 24,
      station_name: '동대입구',
      next_station: [25],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 25,
      station_name: '약수',
      next_station: [26],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 26,
      station_name: '금호',
      next_station: [27],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 27, station_name: '옥수', next_station: [28] }],
  [{ line_id: 3, line_name: '3호선', station_id: 28, station_name: '압구정', next_station: [29] }],
  [{ line_id: 3, line_name: '3호선', station_id: 29, station_name: '신사', next_station: [30] }],

  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 30,
      station_name: '잠원',
      next_station: [31],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 31,
      station_name: '고속터미널',
      next_station: [32],
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 32, station_name: '교대', next_station: [33] }],

  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 33,
      station_name: '남부터미널',
      next_station: [34],
      toilet: 'GATE',
    },
  ],

  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 34,
      station_name: '양재',
      next_station: [35],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 35, station_name: '매봉', next_station: [36] }],
  [{ line_id: 3, line_name: '3호선', station_id: 36, station_name: '도곡', next_station: [37] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 37,
      station_name: '대치',
      next_station: [38],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 38, station_name: '학여울', next_station: [39] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 39,
      station_name: '대청',
      next_station: [40],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 3, line_name: '3호선', station_id: 40, station_name: '일원', next_station: [41] }],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 41,
      station_name: '수서',
      next_station: [42],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 42,
      station_name: '가락시장',
      next_station: [43],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 43,
      station_name: '경찰병원',
      next_station: [44],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 3,
      line_name: '3호선',
      station_id: 44,
      station_name: '오금',
      next_station: undefined,
      toilet: 'GATE',
    },
  ],
];
export const line_3_flat = line_3.reduce(function (acc, cur) {
  return acc.concat(cur);
});
