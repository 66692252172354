import { LineType } from './lines';

export const line_5: LineType[][] = [
  [{ line_id: 5, line_name: '5호선', station_id: 1, station_name: '방화', next_station: [2] }],
  [{ line_id: 5, line_name: '5호선', station_id: 2, station_name: '개화산', next_station: [3] }],
  [{ line_id: 5, line_name: '5호선', station_id: 3, station_name: '김포공항', next_station: [4] }],
  [{ line_id: 5, line_name: '5호선', station_id: 4, station_name: '송정', next_station: [5] }],
  [{ line_id: 5, line_name: '5호선', station_id: 5, station_name: '마곡', next_station: [6] }],
  [{ line_id: 5, line_name: '5호선', station_id: 6, station_name: '발산', next_station: [7] }],
  [{ line_id: 5, line_name: '5호선', station_id: 7, station_name: '우장산', next_station: [8] }],
  [{ line_id: 5, line_name: '5호선', station_id: 8, station_name: '화곡', next_station: [9] }],
  [{ line_id: 5, line_name: '5호선', station_id: 9, station_name: '까치산', next_station: [10] }],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 10,
      station_name: '신정',
      next_station: [11],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 5, line_name: '5호선', station_id: 11, station_name: '목동', next_station: [12] }],
  [{ line_id: 5, line_name: '5호선', station_id: 12, station_name: '오목교', next_station: [13] }],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 13,
      station_name: '양평',
      next_station: [14],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 14,
      station_name: '영등포구청',
      next_station: [15],
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 15,
      station_name: '영등포시장',
      next_station: [16],
    },
  ],
  [{ line_id: 5, line_name: '5호선', station_id: 16, station_name: '신길', next_station: [17] }],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 17,
      station_name: '여의도',
      next_station: [18],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 18,
      station_name: '여의나루',
      next_station: [19],
    },
  ],
  [{ line_id: 5, line_name: '5호선', station_id: 19, station_name: '마포', next_station: [20] }],
  [{ line_id: 5, line_name: '5호선', station_id: 20, station_name: '공덕', next_station: [21] }],
  [{ line_id: 5, line_name: '5호선', station_id: 21, station_name: '애오개', next_station: [22] }],
  [{ line_id: 5, line_name: '5호선', station_id: 22, station_name: '충정로', next_station: [23] }],
  [{ line_id: 5, line_name: '5호선', station_id: 23, station_name: '서대문', next_station: [24] }],
  [{ line_id: 5, line_name: '5호선', station_id: 24, station_name: '광화문', next_station: [25] }],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 25,
      station_name: '종로3가',
      next_station: [26],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 26,
      station_name: '을지로4가',
      next_station: [27],
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 27,
      station_name: '동대문역사문화공원',
      next_station: [28],
    },
  ],
  [{ line_id: 5, line_name: '5호선', station_id: 28, station_name: '청구', next_station: [29] }],
  [{ line_id: 5, line_name: '5호선', station_id: 29, station_name: '신금호', next_station: [30] }],
  [{ line_id: 5, line_name: '5호선', station_id: 30, station_name: '행당', next_station: [31] }],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 31,
      station_name: '왕십리',
      next_station: [32],
      toilet: 'GATE',
    },
  ],

  [{ line_id: 5, line_name: '5호선', station_id: 32, station_name: '마장', next_station: [33] }],
  [{ line_id: 5, line_name: '5호선', station_id: 33, station_name: '답십리', next_station: [34] }],
  [{ line_id: 5, line_name: '5호선', station_id: 34, station_name: '장한평', next_station: [35] }],
  [{ line_id: 5, line_name: '5호선', station_id: 35, station_name: '군자', next_station: [36] }],
  [{ line_id: 5, line_name: '5호선', station_id: 36, station_name: '아차산', next_station: [37] }],
  [{ line_id: 5, line_name: '5호선', station_id: 37, station_name: '광나루', next_station: [38] }],
  [{ line_id: 5, line_name: '5호선', station_id: 38, station_name: '천호', next_station: [39] }],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 39,
      station_name: '강동',
      next_station: [40, 50],
    },
  ],

  [
    { line_id: 5, line_name: '5호선', station_id: 40, station_name: '길동', next_station: [41] },
    { line_id: 5, line_name: '5호선', station_id: 50, station_name: '둔촌동', next_station: [51] },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 41,
      station_name: '굽은다리',
      next_station: [42],
    },
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 51,
      station_name: '올림픽공원',
      next_station: [52],
      toilet: 'GATE',
    },
  ],
  [
    { line_id: 5, line_name: '5호선', station_id: 42, station_name: '명일', next_station: [43] },
    { line_id: 5, line_name: '5호선', station_id: 52, station_name: '방이', next_station: [53] },
  ],
  [
    { line_id: 5, line_name: '5호선', station_id: 43, station_name: '고덕', next_station: [44] },
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 53,
      station_name: '오금',
      next_station: [54],
      toilet: 'GATE',
    },
  ],
  [
    { line_id: 5, line_name: '5호선', station_id: 44, station_name: '상일동', next_station: [45] },
    { line_id: 5, line_name: '5호선', station_id: 54, station_name: '개롱', next_station: [55] },
  ],
  [
    { line_id: 5, line_name: '5호선', station_id: 45, station_name: '강일', next_station: [46] },
    { line_id: 5, line_name: '5호선', station_id: 55, station_name: '거여', next_station: [56] },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 46,
      station_name: '미사',
      next_station: [47],
      toilet: 'GATE',
    },
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 56,
      station_name: '마천',
      next_station: undefined,
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 47,
      station_name: '하남풍산',
      next_station: [48],
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 48,
      station_name: '하남시청',
      next_station: [49],
    },
  ],
  [
    {
      line_id: 5,
      line_name: '5호선',
      station_id: 49,
      station_name: '하남검단산',
      next_station: undefined,
    },
  ],
];
export const line_5_flat = line_5.reduce(function (acc, cur) {
  return acc.concat(cur);
});
