import { LineType } from './lines';

export const line_1: LineType[][] = [
  [{ line_id: 1, line_name: '1호선', station_id: 1, station_name: '소요산', next_station: [2] }],
  [{ line_id: 1, line_name: '1호선', station_id: 2, station_name: '동두천', next_station: [3] }],
  [{ line_id: 1, line_name: '1호선', station_id: 3, station_name: '보산', next_station: [4] }],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 4,
      station_name: '동두천중앙',
      next_station: [5],
    },
  ],
  [{ line_id: 1, line_name: '1호선', station_id: 5, station_name: '지행', next_station: [6] }],
  [{ line_id: 1, line_name: '1호선', station_id: 6, station_name: '덕정', next_station: [7] }],
  [{ line_id: 1, line_name: '1호선', station_id: 7, station_name: '덕계', next_station: [8] }],
  [{ line_id: 1, line_name: '1호선', station_id: 8, station_name: '양주', next_station: [9] }],
  [{ line_id: 1, line_name: '1호선', station_id: 9, station_name: '녹양', next_station: [10] }],
  [{ line_id: 1, line_name: '1호선', station_id: 10, station_name: '가능', next_station: [11] }],
  [{ line_id: 1, line_name: '1호선', station_id: 11, station_name: '의정부', next_station: [12] }],
  [{ line_id: 1, line_name: '1호선', station_id: 12, station_name: '회룡', next_station: [13] }],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 13,
      station_name: '망월사',
      next_station: [14],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 14,
      station_name: '도봉산',
      next_station: [15],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 15,
      station_name: '도봉',
      next_station: [16],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 16,
      station_name: '방학',
      next_station: [17],
      toilet: 'PLATFORM',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 17,
      station_name: '창동',
      next_station: [18],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 18,
      station_name: '녹천',
      next_station: [19],
      toilet: 'PLATFORM',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 19,
      station_name: '월계',
      next_station: [20],
      toilet: 'PLATFORM',
    },
  ],
  [{ line_id: 1, line_name: '1호선', station_id: 20, station_name: '광운대', next_station: [21] }],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 21,
      station_name: '석계',
      next_station: [22],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 22,
      station_name: '신이문',
      next_station: [23],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 23,
      station_name: '외대앞',
      next_station: [24],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 1, line_name: '1호선', station_id: 24, station_name: '회기', next_station: [25] }],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 25,
      station_name: '청량리',
      next_station: [26],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 1, line_name: '1호선', station_id: 26, station_name: '제기동', next_station: [27] }],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 27,
      station_name: '신설동',
      next_station: [28],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 28,
      station_name: '동묘앞',
      next_station: [29],
      toilet: 'PLATFORM',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 29,
      station_name: '동대문',
      next_station: [30],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 1, line_name: '1호선', station_id: 30, station_name: '종로5가', next_station: [31] }],
  [{ line_id: 1, line_name: '1호선', station_id: 31, station_name: '종로3가', next_station: [32] }],
  [{ line_id: 1, line_name: '1호선', station_id: 32, station_name: '종각', next_station: [33] }],
  [{ line_id: 1, line_name: '1호선', station_id: 33, station_name: '시청', next_station: [34] }],
  [{ line_id: 1, line_name: '1호선', station_id: 34, station_name: '서울', next_station: [35] }],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 35,
      station_name: '남영',
      next_station: [36],
      toilet: 'PLATFORM',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 36,
      station_name: '용산',
      next_station: [37],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 1, line_name: '1호선', station_id: 37, station_name: '노량진', next_station: [38] }],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 38,
      station_name: '대방',
      next_station: [39],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 39,
      station_name: '신길',
      next_station: [40],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 40,
      station_name: '영등포',
      next_station: [41],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 41,
      station_name: '신도림',
      next_station: [42],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 42,
      station_name: '구로',
      next_station: [43, 63],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 43,
      station_name: '구일',
      next_station: [44],
      toilet: 'GATE',
    },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 63,
      station_name: '가산디지털단지',
      next_station: [64],
    },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 44, station_name: '개봉', next_station: [45] },
    { line_id: 1, line_name: '1호선', station_id: 64, station_name: '독산', next_station: [65] },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 45, station_name: '오류동', next_station: [46] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 65,
      station_name: '금천구청',
      next_station: [66, 97],
    },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 46, station_name: '온수', next_station: [47] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 66,
      station_name: '석수',
      next_station: [67],
      toilet: 'PLATFORM',
    },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 97,
      station_name: '광명',
      next_station: undefined,
    },
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 47, station_name: '역곡', next_station: [48] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 67,
      station_name: '관악',
      next_station: [68],
      toilet: 'GATE',
    },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 48, station_name: '소사', next_station: [49] },
    { line_id: 1, line_name: '1호선', station_id: 68, station_name: '안양', next_station: [69] },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 49, station_name: '부천', next_station: [50] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 69,
      station_name: '명학',
      next_station: [70],
      toilet: 'PLATFORM',
    },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 50, station_name: '중동', next_station: [51] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 70,
      station_name: '금정',
      next_station: [71],
      toilet: 'PLATFORM',
    },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 51, station_name: '송내', next_station: [52] },
    { line_id: 1, line_name: '1호선', station_id: 71, station_name: '군포', next_station: [72] },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 52, station_name: '부개', next_station: [53] },
    { line_id: 1, line_name: '1호선', station_id: 72, station_name: '당정', next_station: [73] },
    undefined,
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 53,
      station_name: '부평',
      next_station: [54],
      toilet: 'GATE',
    },
    { line_id: 1, line_name: '1호선', station_id: 73, station_name: '의왕', next_station: [74] },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 54, station_name: '백운', next_station: [55] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 74,
      station_name: '성균관대',
      next_station: [75],
    },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 55, station_name: '동암', next_station: [56] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 75,
      station_name: '화서',
      next_station: [76],
      toilet: 'GATE',
    },
    undefined,
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 56,
      station_name: '간석',
      next_station: [57],
      toilet: 'GATE',
    },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 76,
      station_name: '수원',
      next_station: [77],
      toilet: 'GATE',
    },
    undefined,
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 57,
      station_name: '주안',
      next_station: [58],
      toilet: 'GATE',
    },
    { line_id: 1, line_name: '1호선', station_id: 77, station_name: '세류', next_station: [78] },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 58, station_name: '도화', next_station: [59] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 78,
      station_name: '병점',
      next_station: [79, 98],
    },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 59, station_name: '제물포', next_station: [60] },
    { line_id: 1, line_name: '1호선', station_id: 79, station_name: '세마', next_station: [80] },
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 98,
      station_name: '서동탄',
      next_station: undefined,
    },
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 60, station_name: '도원', next_station: [61] },
    { line_id: 1, line_name: '1호선', station_id: 80, station_name: '오산대', next_station: [81] },
    undefined,
  ],
  [
    { line_id: 1, line_name: '1호선', station_id: 61, station_name: '동인천', next_station: [62] },
    { line_id: 1, line_name: '1호선', station_id: 81, station_name: '오산', next_station: [82] },
    undefined,
  ],
  [
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 62,
      station_name: '인천',
      next_station: undefined,
    },
    { line_id: 1, line_name: '1호선', station_id: 82, station_name: '진위', next_station: [83] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 83, station_name: '송탄', next_station: [84] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 84, station_name: '서정리', next_station: [85] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 85, station_name: '지제', next_station: [86] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 86, station_name: '평택', next_station: [87] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 87, station_name: '성환', next_station: [88] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 88, station_name: '직산', next_station: [89] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 89, station_name: '두정', next_station: [90] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 90, station_name: '천안', next_station: [91] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 91, station_name: '봉명', next_station: [92] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 92, station_name: '쌍용', next_station: [93] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 93, station_name: '아산', next_station: [94] },
    undefined,
  ],
  [
    undefined,
    { line_id: 1, line_name: '1호선', station_id: 94, station_name: '배방', next_station: [95] },
    undefined,
  ],
  [
    undefined,
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 95,
      station_name: '온양온천',
      next_station: [96],
    },
    undefined,
  ],
  [
    undefined,
    {
      line_id: 1,
      line_name: '1호선',
      station_id: 96,
      station_name: '신창',
      next_station: undefined,
    },
    undefined,
  ],
];

export const line_1_flat = line_1.reduce(function (acc, cur) {
  return acc.concat(cur);
});
