import { LineType } from './lines';

export const line_2: LineType[][] = [
  [{ line_id: 2, line_name: '2호선', station_id: 1, station_name: '까치산', next_station: [2] }],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 2,
      station_name: '신정네거리',
      next_station: [3],
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 3,
      station_name: '양천구청',
      next_station: [4],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 2, line_name: '2호선', station_id: 4, station_name: '도림천', next_station: [5] }],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 5,
      station_name: '신도림',
      next_station: [6, 30],
      toilet: 'GATE',
    },
  ],

  [
    { line_id: 2, line_name: '2호선', station_id: 6, station_name: '문래', next_station: [7] },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 30,
      station_name: '대림',
      next_station: [31],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 7,
      station_name: '영등포구청',
      next_station: [8],
      toilet: 'GATE',
    },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 31,
      station_name: '구로디지털단지',
      next_station: [32],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 8,
      station_name: '당산',
      next_station: [9],
      toilet: 'GATE',
    },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 32,
      station_name: '신대방',
      next_station: [33],
      toilet: 'GATE',
    },
  ],
  [
    { line_id: 2, line_name: '2호선', station_id: 9, station_name: '합정', next_station: [10] },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 33,
      station_name: '신림',
      next_station: [34],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 10,
      station_name: '홍대입구',
      next_station: [11],
    },
    { line_id: 2, line_name: '2호선', station_id: 34, station_name: '봉천', next_station: [35] },
  ],
  [
    { line_id: 2, line_name: '2호선', station_id: 11, station_name: '신촌', next_station: [12] },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 35,
      station_name: '서울대입구',
      next_station: [36],
    },
  ],
  [
    { line_id: 2, line_name: '2호선', station_id: 12, station_name: '이대', next_station: [13] },
    { line_id: 2, line_name: '2호선', station_id: 36, station_name: '낙성대', next_station: [37] },
  ],
  [
    { line_id: 2, line_name: '2호선', station_id: 13, station_name: '아현', next_station: [14] },
    { line_id: 2, line_name: '2호선', station_id: 37, station_name: '사당', next_station: [38] },
  ],
  [
    { line_id: 2, line_name: '2호선', station_id: 14, station_name: '충정로', next_station: [15] },
    { line_id: 2, line_name: '2호선', station_id: 38, station_name: '방배', next_station: [39] },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 15,
      station_name: '시청',
      next_station: [16],
      toilet: 'GATE',
    },
    { line_id: 2, line_name: '2호선', station_id: 39, station_name: '서초', next_station: [40] },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 16,
      station_name: '을지로입구',
      next_station: [17],
    },
    { line_id: 2, line_name: '2호선', station_id: 40, station_name: '교대', next_station: [41] },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 17,
      station_name: '을지로3가',
      next_station: [18],
      toilet: 'GATE',
    },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 41,
      station_name: '강남',
      next_station: [42],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 18,
      station_name: '을지로4가',
      next_station: [19],
    },
    { line_id: 2, line_name: '2호선', station_id: 42, station_name: '역삼', next_station: [43] },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 19,
      station_name: '동대문역사문화공원',
      next_station: [20],
      toilet: 'GATE',
    },
    { line_id: 2, line_name: '2호선', station_id: 43, station_name: '선릉', next_station: [44] },
  ],
  [
    { line_id: 2, line_name: '2호선', station_id: 20, station_name: '신당', next_station: [21] },
    { line_id: 2, line_name: '2호선', station_id: 44, station_name: '삼성', next_station: [45] },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 21,
      station_name: '상왕십리',
      next_station: [22],
    },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 45,
      station_name: '종합운동장',
      next_station: [46],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 22,
      station_name: '왕십리',
      next_station: [23],
      toilet: 'GATE',
    },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 46,
      station_name: '잠실새내',
      next_station: [47],
    },
  ],
  [
    { line_id: 2, line_name: '2호선', station_id: 23, station_name: '한양대', next_station: [24] },
    { line_id: 2, line_name: '2호선', station_id: 47, station_name: '잠실', next_station: [48] },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 24,
      station_name: '뚝섬',
      next_station: undefined,
    },
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 48,
      station_name: '잠실나루',
      next_station: [49],
    },
  ],
  [
    undefined,
    { line_id: 2, line_name: '2호선', station_id: 49, station_name: '강변', next_station: [50] },
  ],
  [
    undefined,
    { line_id: 2, line_name: '2호선', station_id: 50, station_name: '구의', next_station: [51] },
  ],
  [
    undefined,
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 51,
      station_name: '건대입구',
      next_station: [25],
      toilet: 'GATE',
    },
  ],

  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 25,
      station_name: '성수',
      next_station: [26, 24],
    },
  ],
  [{ line_id: 2, line_name: '2호선', station_id: 26, station_name: '용답', next_station: [27] }],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 27,
      station_name: '신답',
      next_station: [28],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 28,
      station_name: '용두',
      next_station: [29],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 2,
      line_name: '2호선',
      station_id: 29,
      station_name: '신설동',
      next_station: undefined,
      toilet: 'GATE',
    },
  ],
];
export const line_2_flat = line_2.reduce(function (acc, cur) {
  return acc.concat(cur);
});
