import { LineType } from './lines';

export const line_6: LineType[][] = [
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 1,
      station_name: '독바위',
      next_station: [2, 38],
    },
  ],
  [
    { line_id: 6, line_name: '6호선', station_id: 2, station_name: '연신내', next_station: [3] },
    { line_id: 6, line_name: '6호선', station_id: 38, station_name: '불광', next_station: [39] },
  ],
  [
    { line_id: 6, line_name: '6호선', station_id: 3, station_name: '구산', next_station: [4] },
    { line_id: 6, line_name: '6호선', station_id: 39, station_name: '역촌', next_station: [5] },
  ],
  [{ line_id: 6, line_name: '6호선', station_id: 4, station_name: '응암', next_station: [5] }],
  [{ line_id: 6, line_name: '6호선', station_id: 5, station_name: '새절', next_station: [6] }],
  [{ line_id: 6, line_name: '6호선', station_id: 6, station_name: '증산', next_station: [7] }],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 7,
      station_name: '디지털미디어시티',
      next_station: [8],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 8,
      station_name: '월드컵경기장',
      next_station: [9],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 6, line_name: '6호선', station_id: 9, station_name: '마포', next_station: [10] }],
  [{ line_id: 6, line_name: '6호선', station_id: 10, station_name: '망원', next_station: [11] }],
  [{ line_id: 6, line_name: '6호선', station_id: 11, station_name: '합정', next_station: [12] }],
  [{ line_id: 6, line_name: '6호선', station_id: 12, station_name: '상수', next_station: [13] }],
  [{ line_id: 6, line_name: '6호선', station_id: 13, station_name: '광흥창', next_station: [14] }],
  [{ line_id: 6, line_name: '6호선', station_id: 14, station_name: '대흥', next_station: [15] }],
  [{ line_id: 6, line_name: '6호선', station_id: 15, station_name: '공덕', next_station: [16] }],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 16,
      station_name: '효창공원앞',
      next_station: [17],
    },
  ],
  [{ line_id: 6, line_name: '6호선', station_id: 17, station_name: '삼각지', next_station: [18] }],
  [{ line_id: 6, line_name: '6호선', station_id: 18, station_name: '녹사평', next_station: [19] }],
  [{ line_id: 6, line_name: '6호선', station_id: 19, station_name: '이태원', next_station: [20] }],

  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 20,
      station_name: '한강진',
      next_station: [21],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 21,
      station_name: '버티고개',
      next_station: [22],
    },
  ],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 22,
      station_name: '약수',
      next_station: [23],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 6, line_name: '6호선', station_id: 23, station_name: '청구', next_station: [24] }],
  [{ line_id: 6, line_name: '6호선', station_id: 24, station_name: '신당', next_station: [25] }],
  [{ line_id: 6, line_name: '6호선', station_id: 25, station_name: '동묘앞', next_station: [26] }],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 26,
      station_name: '창신',
      next_station: [27],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 6, line_name: '6호선', station_id: 27, station_name: '보문', next_station: [28] }],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 28,
      station_name: '안암',
      next_station: [29],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 29,
      station_name: '고려대',
      next_station: [30],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 6, line_name: '6호선', station_id: 30, station_name: '월곡', next_station: [31] }],
  [{ line_id: 6, line_name: '6호선', station_id: 31, station_name: '상월곡', next_station: [32] }],
  [{ line_id: 6, line_name: '6호선', station_id: 32, station_name: '돌곶이', next_station: [33] }],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 33,
      station_name: '석계',
      next_station: [34],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 34,
      station_name: '태릉입구',
      next_station: [35],
    },
  ],
  [{ line_id: 6, line_name: '6호선', station_id: 35, station_name: '화랑대', next_station: [36] }],
  [{ line_id: 6, line_name: '6호선', station_id: 36, station_name: '봉화산', next_station: [37] }],
  [
    {
      line_id: 6,
      line_name: '6호선',
      station_id: 37,
      station_name: '신내',
      next_station: undefined,
    },
  ],
];
export const line_6_flat = line_6.reduce(function (acc, cur) {
  return acc.concat(cur);
});
