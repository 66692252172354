import { LineType } from './lines';

export const line_sooin: LineType[][] = [
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 1,
      station_name: '청량리',
      next_station: [2],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 2,
      station_name: '왕십리',
      next_station: [3],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 3,
      station_name: '서울숲',
      next_station: [4],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 4,
      station_name: '압구정로데오',
      next_station: [5],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 5,
      station_name: '강남구청',
      next_station: [6],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 6,
      station_name: '선정릉',
      next_station: [7],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 7,
      station_name: '선릉',
      next_station: [8],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 8,
      station_name: '한티',
      next_station: [9],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 9,
      station_name: '도곡',
      next_station: [10],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 10,
      station_name: '구룡',
      next_station: [11],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 11,
      station_name: '개포동',
      next_station: [12],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 12,
      station_name: '대모산입구',
      next_station: [13],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 13,
      station_name: '수서',
      next_station: [14],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 14,
      station_name: '복정',
      next_station: [15],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 15,
      station_name: '가천대',
      next_station: [16],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 16,
      station_name: '태평',
      next_station: [17],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 17,
      station_name: '모란',
      next_station: [18],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 18,
      station_name: '야탑',
      next_station: [19],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 19,
      station_name: '이매',
      next_station: [20],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 20,
      station_name: '서현',
      next_station: [21],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 21,
      station_name: '수내',
      next_station: [22],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 22,
      station_name: '정자',
      next_station: [23],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 23,
      station_name: '미금',
      next_station: [24],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 24,
      station_name: '오리',
      next_station: [25],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 25,
      station_name: '죽전',
      next_station: [26],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 26,
      station_name: '보정',
      next_station: [27],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 27,
      station_name: '구성',
      next_station: [28],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 28,
      station_name: '신갈',
      next_station: [29],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 29,
      station_name: '기흥',
      next_station: [30],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 30,
      station_name: '상갈',
      next_station: [31],
    },
  ],

  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 31,
      station_name: '청명',
      next_station: [32],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 32,
      station_name: '영통',
      next_station: [33],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 33,
      station_name: '망포',
      next_station: [34],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 34,
      station_name: '매탄권선',
      next_station: [35],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 35,
      station_name: '수원시청',
      next_station: [36],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 36,
      station_name: '매교',
      next_station: [37],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 37,
      station_name: '수원',
      next_station: [38],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 38,
      station_name: '고색',
      next_station: [39],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 39,
      station_name: '오목천',
      next_station: [40],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 40,
      station_name: '어천',
      next_station: [41],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 41,
      station_name: '야목',
      next_station: [42],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 42,
      station_name: '사리',
      next_station: [43],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 43,
      station_name: '한대앞',
      next_station: [44],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 44,
      station_name: '중앙',
      next_station: [45],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 45,
      station_name: '고잔',
      next_station: [46],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 46,
      station_name: '초지',
      next_station: [47],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 47,
      station_name: '안산',
      next_station: [48],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 48,
      station_name: '신길온천',
      next_station: [49],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 49,
      station_name: '정왕',
      next_station: [50],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 50,
      station_name: '오이도',
      next_station: [51],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 51,
      station_name: '달월',
      next_station: [52],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 52,
      station_name: '월곶',
      next_station: [53],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 53,
      station_name: '소래포구',
      next_station: [54],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 54,
      station_name: '인천논현',
      next_station: [55],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 55,
      station_name: '호구포',
      next_station: [56],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 56,
      station_name: '남동인더스파크',
      next_station: [57],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 57,
      station_name: '원인재',
      next_station: [58],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 58,
      station_name: '연수',
      next_station: [59],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 59,
      station_name: '송도',
      next_station: [60],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 60,
      station_name: '인하대',
      next_station: [61],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 61,
      station_name: '숭의',
      next_station: [62],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 62,
      station_name: '신포',
      next_station: [63],
    },
  ],
  [
    {
      line_id: 13,
      line_name: '수인분당선',
      station_id: 63,
      station_name: '인천',
      next_station: undefined,
    },
  ],
];
export const line_sooin_flat = line_sooin.reduce(function (acc, cur) {
  return acc.concat(cur);
});
