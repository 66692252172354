import { LineType } from './lines';

export const line_9: LineType[][] = [
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 1,
      station_name: '개화',
      next_station: [2],
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 2,
      station_name: '김포공항',
      next_station: [3],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 9, line_name: '9호선', station_id: 3, station_name: '공항시장', next_station: [4] }],
  [{ line_id: 9, line_name: '9호선', station_id: 4, station_name: '신방화', next_station: [5] }],
  [{ line_id: 9, line_name: '9호선', station_id: 5, station_name: '마곡나루', next_station: [6] }],
  [{ line_id: 9, line_name: '9호선', station_id: 6, station_name: '양천향교', next_station: [7] }],
  [{ line_id: 9, line_name: '9호선', station_id: 7, station_name: '가양', next_station: [8] }],
  [{ line_id: 9, line_name: '9호선', station_id: 8, station_name: '증미', next_station: [9] }],
  [{ line_id: 9, line_name: '9호선', station_id: 9, station_name: '등촌', next_station: [10] }],
  [{ line_id: 9, line_name: '9호선', station_id: 10, station_name: '염창', next_station: [11] }],
  [{ line_id: 9, line_name: '9호선', station_id: 11, station_name: '신목동', next_station: [12] }],
  [{ line_id: 9, line_name: '9호선', station_id: 12, station_name: '선유도', next_station: [13] }],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 13,
      station_name: '당산',
      next_station: [14],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 14,
      station_name: '국회의사당',
      next_station: [15],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 15,
      station_name: '여의도',
      next_station: [16],
      toilet: 'PLATFORM',
    },
  ],
  [{ line_id: 9, line_name: '9호선', station_id: 16, station_name: '샛강', next_station: [17] }],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 17,
      station_name: '노량진',
      next_station: [18],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 9, line_name: '9호선', station_id: 18, station_name: '노들', next_station: [19] }],
  [{ line_id: 9, line_name: '9호선', station_id: 19, station_name: '흑석', next_station: [20] }],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 20,
      station_name: '동작',
      next_station: [21],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 9, line_name: '9호선', station_id: 21, station_name: '구반포', next_station: [22] }],
  [{ line_id: 9, line_name: '9호선', station_id: 22, station_name: '신반포', next_station: [23] }],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 23,
      station_name: '고속터미널',
      next_station: [24],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 9, line_name: '9호선', station_id: 24, station_name: '사평', next_station: [25] }],
  [{ line_id: 9, line_name: '9호선', station_id: 25, station_name: '신논현', next_station: [26] }],

  [{ line_id: 9, line_name: '9호선', station_id: 26, station_name: '언주', next_station: [27] }],
  [{ line_id: 9, line_name: '9호선', station_id: 27, station_name: '선정릉', next_station: [28] }],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 28,
      station_name: '삼성중앙',
      next_station: [29],
    },
  ],
  [{ line_id: 9, line_name: '9호선', station_id: 29, station_name: '봉은사', next_station: [30] }],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 30,
      station_name: '종합운동장',
      next_station: [31],
    },
  ],
  [{ line_id: 9, line_name: '9호선', station_id: 31, station_name: '삼전', next_station: [32] }],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 32,
      station_name: '석촌고분',
      next_station: [33],
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 33,
      station_name: '석촌',
      next_station: [34],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 34,
      station_name: '송파나루',
      next_station: [35],
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 35,
      station_name: '한성백제',
      next_station: [36],
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 36,
      station_name: '올림픽공원',
      next_station: [37],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 37,
      station_name: '둔촌오륜',
      next_station: [38],
    },
  ],
  [
    {
      line_id: 9,
      line_name: '9호선',
      station_id: 38,
      station_name: '중앙보훈병원',
      next_station: undefined,
    },
  ],
];
export const line_9_flat = line_9.reduce(function (acc, cur) {
  return acc.concat(cur);
});
