import { LineType } from './lines';

export const line_7: LineType[][] = [
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 1,
      station_name: '장암',
      next_station: [2],
    },
  ],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 2,
      station_name: '도봉산',
      next_station: [3],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 3, station_name: '수락산', next_station: [4] }],
  [{ line_id: 7, line_name: '7호선', station_id: 4, station_name: '마들', next_station: [5] }],
  [{ line_id: 7, line_name: '7호선', station_id: 5, station_name: '노원', next_station: [6] }],
  [{ line_id: 7, line_name: '7호선', station_id: 6, station_name: '중계', next_station: [7] }],
  [{ line_id: 7, line_name: '7호선', station_id: 7, station_name: '하계', next_station: [8] }],
  [{ line_id: 7, line_name: '7호선', station_id: 8, station_name: '공릉', next_station: [9] }],
  [{ line_id: 7, line_name: '7호선', station_id: 9, station_name: '태릉입구', next_station: [10] }],
  [{ line_id: 7, line_name: '7호선', station_id: 10, station_name: '먹골', next_station: [11] }],
  [{ line_id: 7, line_name: '7호선', station_id: 11, station_name: '중화', next_station: [12] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 12,
      station_name: '상봉',
      next_station: [13],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 13, station_name: '면목', next_station: [14] }],
  [{ line_id: 7, line_name: '7호선', station_id: 14, station_name: '사가정', next_station: [15] }],
  [{ line_id: 7, line_name: '7호선', station_id: 15, station_name: '용마산', next_station: [16] }],
  [{ line_id: 7, line_name: '7호선', station_id: 16, station_name: '중곡', next_station: [17] }],
  [{ line_id: 7, line_name: '7호선', station_id: 17, station_name: '군자', next_station: [18] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 18,
      station_name: '어린이대공원',
      next_station: [19],
    },
  ],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 19,
      station_name: '건대입구',
      next_station: [20],
      toilet: 'PLATFORM',
    },
  ],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 20,
      station_name: '뚝섬유원지',
      next_station: [21],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 21, station_name: '청담', next_station: [22] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 22,
      station_name: '강남구청',
      next_station: [23],
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 23, station_name: '학동', next_station: [24] }],
  [{ line_id: 7, line_name: '7호선', station_id: 24, station_name: '논현', next_station: [25] }],
  [{ line_id: 7, line_name: '7호선', station_id: 25, station_name: '반포', next_station: [26] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 26,
      station_name: '고속터미널',
      next_station: [27],
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 27, station_name: '내방', next_station: [28] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 28,
      station_name: '이수',
      next_station: [29],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 29,
      station_name: '남성',
      next_station: [30],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 30,
      station_name: '숭실대입구',
      next_station: [31],
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 31, station_name: '상도', next_station: [32] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 32,
      station_name: '장승배기',
      next_station: [33],
    },
  ],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 33,
      station_name: '신대방삼거리',
      next_station: [34],
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 34, station_name: '보라매', next_station: [35] }],
  [{ line_id: 7, line_name: '7호선', station_id: 35, station_name: '신풍', next_station: [36] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 36,
      station_name: '대림',
      next_station: [37],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 37, station_name: '남구로', next_station: [38] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 38,
      station_name: '가산디지털단지',
      next_station: [39],
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 39, station_name: '철산', next_station: [40] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 40,
      station_name: '광명사거리',
      next_station: [41],
    },
  ],

  [{ line_id: 7, line_name: '7호선', station_id: 41, station_name: '천왕', next_station: [42] }],
  [{ line_id: 7, line_name: '7호선', station_id: 42, station_name: '온수', next_station: [43] }],
  [{ line_id: 7, line_name: '7호선', station_id: 43, station_name: '까치울', next_station: [44] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 44,
      station_name: '부천종합운동장',
      next_station: [45],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 45, station_name: '춘의', next_station: [46] }],
  [{ line_id: 7, line_name: '7호선', station_id: 46, station_name: '신중동', next_station: [47] }],

  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 47,
      station_name: '부천시청',
      next_station: [48],
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 48, station_name: '상동', next_station: [49] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 49,
      station_name: '삼산체육관',
      next_station: [50],
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 50, station_name: '굴포천', next_station: [51] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 51,
      station_name: '부평구청',
      next_station: [52],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 7, line_name: '7호선', station_id: 52, station_name: '산곡', next_station: [53] }],
  [
    {
      line_id: 7,
      line_name: '7호선',
      station_id: 53,
      station_name: '석남',
      next_station: undefined,
      toilet: 'GATE',
    },
  ],
];

export const line_7_flat = line_7.reduce(function (acc, cur) {
  return acc.concat(cur);
});
