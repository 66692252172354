import { LineType } from './lines';

export const line_incheon: LineType[][] = [
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 1,
      station_name: '계양',
      next_station: [2],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 2,
      station_name: '귤현',
      next_station: [3],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 3,
      station_name: '박촌',
      next_station: [4],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 4,
      station_name: '임학',
      next_station: [5],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 5,
      station_name: '계산',
      next_station: [6],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 6,
      station_name: '경인교대입구',
      next_station: [7],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 7,
      station_name: '작전',
      next_station: [8],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 8,
      station_name: '갈산',
      next_station: [9],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 9,
      station_name: '부평구청',
      next_station: [10],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 10,
      station_name: '부평시장',
      next_station: [11],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 11,
      station_name: '부평',
      next_station: [12],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 12,
      station_name: '동수',
      next_station: [13],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 13,
      station_name: '부평삼거리',
      next_station: [14],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 14,
      station_name: '간석오거리',
      next_station: [15],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 15,
      station_name: '인천시청',
      next_station: [16],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 16,
      station_name: '예술회관',
      next_station: [17],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 17,
      station_name: '인천터미널',
      next_station: [18],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 18,
      station_name: '문학경기장',
      next_station: [19],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 19,
      station_name: '선학',
      next_station: [20],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 20,
      station_name: '신연수',
      next_station: [21],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 21,
      station_name: '원인재',
      next_station: [22],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 22,
      station_name: '동춘',
      next_station: [23],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 23,
      station_name: '동막',
      next_station: [24],
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 24,
      station_name: '캠퍼스타운',
      next_station: [25],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 25,
      station_name: '테크노파크',
      next_station: [26],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 26,
      station_name: '지식정보단지',
      next_station: [27],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 27,
      station_name: '인천대입구',
      next_station: [28],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 28,
      station_name: '센트럴파크',
      next_station: [29],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 29,
      station_name: '국제업무지구',
      next_station: [30],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 16,
      line_name: '인천1&2호선',
      station_id: 30,
      station_name: '송도달빛축제공원',
      next_station: undefined,
      toilet: 'GATE',
    },
  ],
];
export const line_incheon_flat = line_incheon.reduce(function (acc, cur) {
  return acc.concat(cur);
});
