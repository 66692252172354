import { LineType } from './lines';

export const line_4: LineType[][] = [
  [{ line_id: 4, line_name: '4호선', station_id: 1, station_name: '당고개', next_station: [2] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 2,
      station_name: '상계',
      next_station: [3],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 3,
      station_name: '노원',
      next_station: [4],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 4,
      station_name: '창동',
      next_station: [5],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 5, station_name: '쌍문', next_station: [6] }],
  [{ line_id: 4, line_name: '4호선', station_id: 6, station_name: '수유', next_station: [7] }],
  [{ line_id: 4, line_name: '4호선', station_id: 7, station_name: '미아', next_station: [8] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 8,
      station_name: '미아사거리',
      next_station: [9],
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 9, station_name: '길음', next_station: [10] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 10,
      station_name: '성신여대입구',
      next_station: [11],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 11,
      station_name: '한성대입구',
      next_station: [12],
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 12, station_name: '혜화', next_station: [13] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 13,
      station_name: '동대문',
      next_station: [14],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 14,
      station_name: '동대문역사문화공원',
      next_station: [15],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 15,
      station_name: '충무로',
      next_station: [16],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 16,
      station_name: '명동',
      next_station: [17],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 17,
      station_name: '회현',
      next_station: [18],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 18, station_name: '서울역', next_station: [19] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 19,
      station_name: '숙대입구',
      next_station: [20],
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 20,
      station_name: '삼각지',
      next_station: [21],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 21, station_name: '신용산', next_station: [22] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 22,
      station_name: '이촌',
      next_station: [23],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 23,
      station_name: '동작',
      next_station: [24],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 24,
      station_name: '이수',
      next_station: [25],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 25,
      station_name: '사당',
      next_station: [26],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 26,
      station_name: '남태령',
      next_station: [27],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 27, station_name: '선바위', next_station: [28] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 28,
      station_name: '경마공원',
      next_station: [29],
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 29, station_name: '대공원', next_station: [30] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 30,
      station_name: '과천',
      next_station: [31],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 31,
      station_name: '정부과천청사',
      next_station: [32],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 32, station_name: '인덕원', next_station: [33] }],
  [{ line_id: 4, line_name: '4호선', station_id: 33, station_name: '평촌', next_station: [34] }],
  [{ line_id: 4, line_name: '4호선', station_id: 34, station_name: '범계', next_station: [35] }],
  [
    {
      line_id: 4,
      line_name: '4호선',
      station_id: 35,
      station_name: '금정',
      next_station: [36],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 4, line_name: '4호선', station_id: 36, station_name: '산본', next_station: [37] }],
  [{ line_id: 4, line_name: '4호선', station_id: 37, station_name: '수리산', next_station: [38] }],

  [{ line_id: 4, line_name: '5호선', station_id: 38, station_name: '대야미', next_station: [39] }],
  [{ line_id: 4, line_name: '5호선', station_id: 39, station_name: '반월', next_station: [40] }],
  [
    {
      line_id: 4,
      line_name: '5호선',
      station_id: 40,
      station_name: '상록수',
      next_station: [41],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '5호선',
      station_id: 41,
      station_name: '한대앞',
      next_station: [42],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '5호선',
      station_id: 42,
      station_name: '중앙',
      next_station: [43],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '5호선',
      station_id: 43,
      station_name: '고잔',
      next_station: [44],
      toilet: 'GATE',
    },
  ],
  [
    {
      line_id: 4,
      line_name: '5호선',
      station_id: 44,
      station_name: '초지',
      next_station: [45],
      toilet: 'GATE',
    },
  ],
  [{ line_id: 4, line_name: '5호선', station_id: 45, station_name: '안산', next_station: [46] }],
  [
    {
      line_id: 4,
      line_name: '5호선',
      station_id: 46,
      station_name: '신길온천',
      next_station: [47],
    },
  ],
  [{ line_id: 4, line_name: '5호선', station_id: 47, station_name: '정왕', next_station: [48] }],
  [
    {
      line_id: 4,
      line_name: '5호선',
      station_id: 48,
      station_name: '오이도',
      next_station: undefined,
    },
  ],
];
export const line_4_flat = line_4.reduce(function (acc, cur) {
  return acc.concat(cur);
});
